<template>
  <div>
    <Breadscrumbs :items="breadcrumbs" class="header-bread-scrum" />
    <v-layout column>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form @submit.prevent="submit">
          <v-layout column class="border-primary rounded-lg px-8 pt-4">
            <v-layout>
              <!--Ten buu cuc-->
              <v-layout column col-6 class="pr-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("post_office_name") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="textRequired"
                  vid="name_post_office"
                  :rules="requiredRules"
                >
                  <v-text-field
                    v-model="postOfficeName"
                    outlined
                    dense
                    disabled
                    filled
                    class="rounded-lg"
                    :maxlength="maximumSmallText"
                    :error-messages="errors"
                    :placeholder="$t('place_holders.post_office_name')"
                  />
                </validation-provider>
              </v-layout>
              <!--email-->
              <v-layout column col-6 class="pr-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("email") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  :rules="emailRules"
                >
                  <v-text-field
                    v-model="email"
                    outlined
                    dense
                    class="rounded-lg"
                    :maxlength="maximumEmail"
                    :error-messages="errors"
                    :placeholder="$t('place_holders.email')"
                  />
                </validation-provider>
              </v-layout>
            </v-layout>
            <!---->
            <v-layout>
              <!--code-->
              <v-layout column col-6 class="pr-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("code_post_office") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="textRequired"
                  vid="code"
                  :rules="requiredRules"
                >
                  <v-text-field
                    v-model="postOfficeCode"
                    outlined
                    dense
                    disabled
                    filled
                    class="rounded-lg"
                    :maxlength="maximumSmallText"
                    :error-messages="errors"
                    :placeholder="$t('place_holders.code_post_office')"
                    @change="postOfficeCode = trimSpace(postOfficeCode)"
                  />
                </validation-provider>
              </v-layout>
              <!--SDT-->
              <v-layout column col-6 class="pr-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("phone") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="phone"
                  :rules="phoneRules"
                >
                  <v-text-field
                    v-model="phone"
                    outlined
                    dense
                    class="rounded-lg"
                    :maxlength="maximumPhone"
                    :error-messages="errors"
                    :placeholder="$t('place_holders.phone')"
                    @keypress="isNumber($event)"
                  />
                </validation-provider>
              </v-layout>
            </v-layout>
            <!--chon tinh/huyen/xa-->
            <v-layout>
              <v-layout column col-6 class="pr-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("province") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="selectRequire"
                  vid="provinceOrder"
                  :rules="requiredRules"
                >
                  <v-autocomplete
                    ref="inputProvinceOrder"
                    v-model="provinceOrderCodeSelected"
                    outlined
                    dense
                    class="rounded-lg"
                    item-text="name"
                    item-value="code"
                    hide-no-data
                    :items="lstProvince"
                    :filter="filterItems"
                    :placeholder="$t('place_holders.province')"
                    :error-messages="errors"
                    @change="onChangeProvinceOrder"
                  />
                </validation-provider>
              </v-layout>
              <v-layout column col-6 class="pr-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("district") }}
                </label>
                <v-autocomplete
                  ref="inputDistrictOrder"
                  v-model="districtOrderSelected"
                  outlined
                  dense
                  class="rounded-lg"
                  item-text="name"
                  item-value="code"
                  hide-no-data
                  :items="lstDistrictOrder"
                  :filter="filterItems"
                  :placeholder="$t('place_holders.district')"
                  :disabled="districtDisabledOrder"
                  @change="onChangeDistrictOrder"
                />
              </v-layout>
            </v-layout>
            <v-layout>
              <v-layout column col-6 class="pr-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("commune") }}
                </label>
                <v-autocomplete
                  ref="inputCommuneOrder"
                  v-model="communeOrderSelected"
                  outlined
                  dense
                  class="rounded-lg"
                  item-text="name"
                  item-value="code"
                  hide-no-data
                  :items="lstCommuneOrder"
                  :filter="filterItems"
                  :placeholder="$t('place_holders.commune')"
                  :disabled="communeDisabledOrder"
                  @change="onChangeCommuneOrder"
                />
              </v-layout>
              <!--dia chi-->
              <v-layout column col-6 class="pr-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("address") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="textRequired"
                  vid="addressPost"
                  :rules="requiredRules"
                >
                  <v-text-field
                    v-model="addressPost"
                    outlined
                    dense
                    class="rounded-lg"
                    :maxlength="maximumSmallText"
                    :error-messages="errors"
                    :placeholder="$t('place_holders.type_custom_address')"
                  />
                </validation-provider>
              </v-layout>
            </v-layout>
            <v-layout v-if="selectStatusPo !== undefined" column col-6 class="pr-6 py-0">
              <label class="text-subtitle-1">
                {{ $t("price_list_po_one") }}
              </label>
              <v-select
                v-model="selectStatusPo"
                :items="lstStatusPo"
                item-text="name"
                item-value="value"
                outlined
                disabled
                filled
                dense
                class="rounded-lg"
              />
            </v-layout>
          </v-layout>
          <v-layout justify-end>
            <v-btn
              color="primary"
              type="submit"
              class="rounded-lg mt-4"
              :disabled="invalid"
            >
              {{ $t("save") }}
            </v-btn>
          </v-layout>
        </v-form>
      </validation-observer>
      <!-- Dialog loading -->
      <dialog-loading v-model="showLoading" :message="$t('processing')" />

      <!-- notifications -->
      <dialog-notification
        v-model="showNoti"
        :persistent="persistent"
        :icon-src="getIcon"
        :message="message"
      />
    </v-layout>
  </div>
</template>

<script>
import Breadscrumbs from '@/components/base/Breadscrumbs'
import DialogLoading from "@/components/dialog/DialogLoading.vue";
import DialogNotification from "@/components/dialog/DialogNotification.vue";

import routePaths from "@/router/routePaths";
import filterItems from "@/helpers/filterItems";
import mixinHandleOrderCreate from "@/views/create_order/mixins/mixinHandleOrderCreate";
import constants from "@/constants";

import { StorageService } from "@/services/storageService";
import { FormUtils } from "@/helpers/formUtils";
import { PostService } from "@/services/postService";
import { StringUtils } from "@/helpers/stringUtils";

export default {
  components: {
    Breadscrumbs,
    DialogLoading,
    DialogNotification
  },
  mixins: [mixinHandleOrderCreate, filterItems],
  data() {
    return {
      requiredRules: {
        required: true,
      },
      constants,
      breadcrumbs: [
        {
          text: 'list_post_offices',
          disabled: false,
          isActive: false,
          href: routePaths.POST_OFFICE_LIST
        },
        {
          text: 'detail-post-office',
          disabled: true,
          isActive: true,
        },
      ],
      postOfficeName: "",
      postOfficeCode: "",
      addressPost: "",
      maximumSmallText: constants.maximumSmallText,
      maximumPhone: constants.maximumPhone,
      phone: "",
      email: "",
      maximumEmail: constants.maximumEmail,
      emailRules: {
        required: true,
        email: true,
        min: constants.minimumEmail,
        max: constants.maximumEmail,
        regex: constants.regExp.email,
      },
      lstProvince: [],
      showLoading: false,
      showNoti: false,
      persistent: false,
      typeNoti: constants.typeAlert.warning,
      message: "",
      id: 0,
      detail: null,
      provinceOrderCodeSelected: null,
      districtOrderSelected: null,
      communeOrderSelected: null,
      currentRole: JSON.parse(localStorage.getItem("CURRENT_USER")).roles,
      currentPoId: JSON.parse(localStorage.getItem("CURRENT_USER")).poId,
      selectStatusPo: null,
      lstStatusPo: [
        {
          name: this.$t("private"),
          value: 0
        },
        {
          name: this.$t("public"),
          value: 1
        }
      ]
    }
  },
  computed: {
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti);
    },
  },
  created() {
    this.id = this.$route.query.id;
    if (window.location.pathname && !this.currentRole.includes("ROLE_ADMIN") || this.currentPoId !== 2) {
      this.toggleDialogNoti({
        state: true,
        msg: this.$t("no-mission-msg"),
      });
      setTimeout(() => {
        this.$router.go(-1);
      }, constants.timeOutRole);
    }
  },
  beforeMount() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.getProvinces();
      this.getDetail()
    },
    async getProvinces() {
      this.lstProvince = await StorageService.getProvinces();
    },
    async getDetail() {
      this.showLoading = true;
      const { status, data } = await PostService.detailPostOffice(this.id);
      this.showLoading = false;
      if (status === constants.statusCode.ok) {
        this.detail = data.payload;
        this.selectStatusPo = this.detail.privateFlag
        this.parseFormData(data.payload)
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
      }
    },
    async parseFormData(data) {
      this.postOfficeName = data.name;
      this.email = data.email;
      this.postOfficeCode = data.code;
      this.phone = data.phone;
      this.addressPost = data.addressDetail;
      this.provinceOrderCodeSelected = data.provinceCode
      this.districtOrderSelected = data.districtCode
      this.communeOrderSelected = data.communeCode
      if (data.provinceCode) {
        this.getDistrictByProvinceCodeOrder(data.provinceCode);
      }
      if (data.districtCode) {
        this.getCommuneByDistrictCodeOrder(data.districtCode)
      }
    },
    async submit() {
      var valid = this.$refs.observer.validate();
      if (valid) {
        this.showLoading = true;
        const pars = this.bindRequestPars();
        const { status, data } = await PostService.editPostOffice(pars);
        this.showLoading = false;
        if (status === constants.statusCode.ok) {
          this.toggleDialogNoti({
            state: true,
            persistent: true,
            type: constants.typeAlert.success,
            msg: this.$t("edit_post_office_success"),
          });
          setTimeout(() => {
            this.$router.push(routePaths.POST_OFFICE_LIST);
          }, 2500);
        } else {
          this.toggleDialogNoti({ state: true, msg: data.message });
          setTimeout(() => {
            this.toggleDialogNoti();
          }, constants.timeOut);
        }
      }
    },
    bindRequestPars() {
      const pars = {
        id: this.id,
        email: this.email,
        phone: this.phone,
        provinceCode: this.provinceOrderCodeSelected,
        districtCode: this.districtOrderSelected,
        communeCode: this.communeOrderSelected,
        addressDetail: this.addressPost
      }
      return pars
    },
    trimSpace(val) {
      return val ? val.trim() : "";
    },
    toggleDialogNoti(
      pars = {
        state: false,
        persistent: false,
        type: constants.typeAlert.success,
        msg: "",
      }
    ) {
      this.showNoti = pars.state;
      this.persistent = pars.persistent;
      this.typeNoti = pars.type;
      this.message = pars.msg;
    },
    isNumber(evt) {
      return FormUtils.isNumber(evt);
    },
  },
}
</script>
<style src="@/styles/createMultiOrder.scss" lang="scss">
</style>
